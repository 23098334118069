import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { getStore } from "./utils/UniversalFunction";

import "./styles/globals.scss";
import "./styles/custom.css";
import Pointhistory from "./Pages/Admin/Pointhistory";
import Topuser from "./Pages/Admin/Topuser";
import Organizations from "./Pages/Admin/User/Organizations";
import OrganizationProfile from "./Pages/Admin/User/OrganizationProfile/OrganizationProfile";
import { ToastContainer } from "react-toastify";

const Loading = lazy(() => import("./components/Loading/Loading"));

const Login = lazy(() => import("./components/UserLogin/Login")); 
const Forgot = lazy(() => import("./components/UserLogin/Forgot")); 
const ResetPassword = lazy(() =>
  import("./components/UserLogin/ResetPassword")
);

const Dashboard = lazy(() => import("./Pages/Admin/Dashboard"));
const User = lazy(() => import("./Pages/Admin/User/User"));
const UserProfile = lazy(() =>
  import("./Pages/Admin/User/UserProfile/UserProfile")
);
const Rank = lazy(() => import("./Pages/Admin/Rank"));
const Competitions = lazy(() => import("./Pages/Admin/Competitions"));
const Redemption = lazy(() => import("./Pages/Admin/Redemption"));
const RedeemHistory = lazy(() => import("./Pages/Admin/Redeemhistory"));
const UserPoints = lazy(() => import("./Pages/Admin/Userpoints"));
const Badges = lazy(() => import("./Pages/Admin/Badges"));
const Feedbacks = lazy(() => import("./Pages/Admin/Feedbacks"));
const FeedbackDetails = lazy(() => import("./Pages/Admin/FeedbackDetails"));  
const Coupons = lazy(() => import("./Pages/Admin/Coupons"));
const Reports = lazy(() => import("./Pages/Admin/Reports"));
const Plan = lazy(() => import("./Pages/Admin/Plan"));
const Orders = lazy(() => import("./Pages/Admin/Orders"));
const Organizationcommission = lazy(() => import("./Pages/Admin/Organizationcommission"));
const Setting = lazy(() => import("./Pages/Admin/Setting"));
const Notifications = lazy(() => import("./Pages/Admin/Notifications")); 
const AddNotification = lazy(() => import("./Pages/Admin/AddNotification")); 
const NotificationDetail = lazy(() => import("./Pages/Admin/NotificationDetail")); 
const NotificationDataPage = lazy(() => import("./Pages/Admin/NotificationDataPage")); 
const PageNotFound = lazy(() =>
  import("./components/PageNotFound/PageNotFound")
);

const PrivateRoute = () => {
  const auth = getStore("user");
  return auth ? <Outlet /> : <Navigate to="/" />;
};

const LoginRoute = () => {
  const auth = getStore("user");
  return !auth ? <Outlet /> : <Navigate to="/admin/dashboard" />;
};

const App = () => {
  return (
    <>
      <ToastContainer />

      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<LoginRoute />}>
            <Route path="/" element={<Login />} />
          </Route>

          <Route path="/forgot" element={<LoginRoute />}>
            <Route path="/forgot" element={<Forgot />} />
          </Route>

          <Route path="/reset/:token" element={<ResetPassword />} /> 

          <Route path="/admin/dashboard" element={<PrivateRoute />}>
            <Route path="/admin/dashboard" element={<Dashboard />} />
          </Route>

          <Route path="/admin/users" element={<PrivateRoute />}>
            <Route path="/admin/users" element={<User />} />
          </Route>

          <Route path="/admin/user/:uuid" element={<PrivateRoute />}>
            <Route path="/admin/user/:uuid" element={<UserProfile />} />
          </Route>

          <Route path="/admin/organizations" element={<PrivateRoute />}>
            <Route path="/admin/organizations" element={<Organizations />} />
          </Route>

          <Route path="/admin/organization/:uuid" element={<PrivateRoute />}>
            <Route path="/admin/organization/:uuid" element={<OrganizationProfile />} />
          </Route>

          <Route path="/admin/topuser" element={<PrivateRoute />}>
            <Route path="/admin/topuser" element={<Topuser />} />
          </Route>

          <Route path="/admin/pointhistory" element={<PrivateRoute />}>
            <Route path="/admin/pointhistory" element={<Pointhistory />} />
          </Route>

          <Route path="/admin/rank" element={<PrivateRoute />}>
            <Route path="/admin/rank" element={<Rank />} />
          </Route>

          <Route path="/admin/competitions" element={<PrivateRoute />}>
            <Route path="/admin/competitions" element={<Competitions />} />
          </Route>

          <Route path="/admin/redemption" element={<PrivateRoute />}>
            <Route path="/admin/redemption" element={<Redemption />} />
          </Route>

          <Route path="/admin/redeemhistory" element={<PrivateRoute />}>
            <Route path="/admin/redeemhistory" element={<RedeemHistory />} />
          </Route>

          <Route path="/admin/userpoints" element={<PrivateRoute />}>
            <Route path="/admin/userpoints" element={<UserPoints />} />
          </Route>

          <Route path="/admin/badges" element={<PrivateRoute />}>
            <Route path="/admin/badges" element={<Badges />} />
          </Route>

          <Route path="/admin/feedbacks" element={<PrivateRoute />}>
            <Route path="/admin/feedbacks" element={<Feedbacks />} />
          </Route>

          <Route path="/admin/feedback/:id" element={<PrivateRoute />}>
            <Route path="/admin/feedback/:id" element={<FeedbackDetails />} />
          </Route> 

          <Route path="/admin/coupons/:id" element={<PrivateRoute />}>
            <Route path="/admin/coupons/:id" element={<Coupons />} />
          </Route>

          <Route path="/admin/report" element={<PrivateRoute />}>
            <Route path="/admin/report" element={<Reports />} />
          </Route>

          <Route path="/admin/plan" element={<PrivateRoute />}>
            <Route path="/admin/plan" element={<Plan />} />
          </Route>

          <Route path="/admin/orders" element={<PrivateRoute />}>
            <Route path="/admin/orders" element={<Orders />} />
          </Route>

          <Route path="/admin/organizationcommission" element={<PrivateRoute />}>
            <Route path="/admin/organizationcommission" element={<Organizationcommission />} />
          </Route>

          <Route path="/admin/addnotification/" element={<PrivateRoute />}>
            <Route path="/admin/addnotification/" element={<AddNotification />} />
          </Route>

          <Route path="/admin/notificationdatapage/:id" element={<PrivateRoute />}>
            <Route path="/admin/notificationdatapage/:id" element={<NotificationDataPage />} />
          </Route>

          <Route path="/admin/notificationdetail/:id" element={<PrivateRoute />}>
            <Route path="/admin/notificationdetail/:id" element={<NotificationDetail />} />
          </Route>

          <Route path="/admin/setting" element={<PrivateRoute />}>
            <Route path="/admin/setting" element={<Setting />} />
          </Route>

          <Route path="/admin/notifications" element={<PrivateRoute />}>
            <Route path="/admin/notifications" element={<Notifications />} />
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
