import React from "react";
import {
  Dashboard,
  User,
  Rank,
  Competition,
  Redemtion,
  Badges, 
  Report,
  Plan,
  Order,
  Setting,
  Logout,
  Logo,
} from "../../Assets/indexImg";
import sidebarCss from "../../styles/sidebar.module.scss";
import { removeItem } from "../../utils/UniversalFunction";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router";

const Sidebar = ({
  sidebar,
  DashboardPage,
  UserPage,
  RankPage,
  CompetitionPage,
  RedemptionPage,
  RedeemHistory,
  UserPoints,
  BadgesPage,
  FeedbacksPage,
  CouponsPage,
  ReportsPage,
  PlanPage,
  OrdersPage,
  SettingsPage,
  OrganizationcommissionPage,
}) => {
  const history = useNavigate();

  const logout = () => {
    removeItem("user");

    history("/");
  };

  return (
    <>
      <div className={sidebar ? sidebarCss.full : sidebarCss.sidebar}>
        <div className={sidebarCss.logo_sidebar}>
          <img alt="" className="img-fluid" src={Logo} />
        </div>

        <ul>
          <li className={DashboardPage && sidebarCss.active}>
            <NavLink to="/admin/dashboard" className={sidebarCss.wrapper_link}>
              <span>
                <img
                  alt=""
                  className={`${sidebarCss.wrapper_img} img-fluid`}
                  src={Dashboard}
                />
              </span>

              <p>Dashboard</p>
            </NavLink>
          </li>

          <li className={UserPage && sidebarCss.active}>
            <NavLink to="/admin/users" className={sidebarCss.wrapper_link}>
              <span>
                <img
                  alt=""
                  className={`${sidebarCss.wrapper_img} img-fluid`}
                  src={User}
                />
              </span>

              <p>Users</p>
            </NavLink>
          </li>

          <li className={RankPage && sidebarCss.active}>
            <NavLink to="/admin/rank" className={sidebarCss.wrapper_link}>
              <span>
                <img
                  alt=""
                  className={`${sidebarCss.wrapper_img} img-fluid`}
                  src={Rank}
                />
              </span>

              <p>Ranks</p>
            </NavLink>
          </li> 

          <li className={CompetitionPage && sidebarCss.active}>
            <NavLink
              to="/admin/competitions"
              className={sidebarCss.wrapper_link}
            >
              <span>
                <img
                  alt=""
                  className={`${sidebarCss.wrapper_img} img-fluid`}
                  src={Competition}
                />
              </span>

              <p>Competitions</p>
            </NavLink>
          </li>

          <li className={RedemptionPage && sidebarCss.active}>
            <NavLink to="/admin/redemption" className={sidebarCss.wrapper_link}>
              <span>
                <img
                  alt=""
                  className={`${sidebarCss.wrapper_img} img-fluid`}
                  src={Redemtion}
                />
              </span>

              <p>Redemption</p>
            </NavLink>
          </li>

          <li className={RedeemHistory && sidebarCss.active}>
            <NavLink to="/admin/redeemhistory" className={sidebarCss.wrapper_link}>
              <span>
                <i className="fa fa-history"></i>
              </span>

              <p>Redeem History</p>
            </NavLink>
          </li>

          <li className={UserPoints && sidebarCss.active}>
            <NavLink to="/admin/userpoints" className={sidebarCss.wrapper_link}>
              <span>
                <i className="fa fa-list-ul"></i>
              </span>

              <p>User Points Setting</p>
            </NavLink>
          </li>

          <li className={BadgesPage && sidebarCss.active}>
            <NavLink to="/admin/badges" className={sidebarCss.wrapper_link}>
              <span>
                <img
                  alt=""
                  className={`${sidebarCss.wrapper_img} img-fluid`}
                  src={Badges}
                />
              </span>

              <p>Badges</p>
            </NavLink>
          </li>

          <li className={FeedbacksPage && sidebarCss.active}>
            <NavLink to="/admin/feedbacks" className={sidebarCss.wrapper_link}>
              <span>
                <i class="fa fa-list"></i>
              </span> 
              <p>Feedbacks</p>
            </NavLink>
          </li>
          
          <li className={ReportsPage && sidebarCss.active}>
            <NavLink to="/admin/report" className={sidebarCss.wrapper_link}>
              <span>
                <img
                  alt=""
                  className={`${sidebarCss.wrapper_img} img-fluid`}
                  src={Report}
                />
              </span> 
              <p>Reports</p>
            </NavLink>
          </li>

          <li className={PlanPage && sidebarCss.active}>
            <NavLink to="/admin/plan" className={sidebarCss.wrapper_link}>
              <span>
                <img
                  alt=""
                  className={`${sidebarCss.wrapper_img} img-fluid`}
                  src={Plan}
                />
              </span>

              <p>Plans</p>
            </NavLink>
          </li>

          <li className={OrdersPage && sidebarCss.active}>
            <NavLink to="/admin/orders" className={sidebarCss.wrapper_link}>
              <span>
                <img
                  alt=""
                  className={`${sidebarCss.wrapper_img} img-fluid`}
                  src={Order}
                />
              </span>

              <p>Orders</p>
            </NavLink>
          </li>

          {/*   <li className={OrganizationcommissionPage && sidebarCss.active}>
            <NavLink to="/admin/organizationcommission" className={sidebarCss.wrapper_link}>
              <span>
                <img
                  alt=""
                  className={`${sidebarCss.wrapper_img} img-fluid`}
                  src={Order}
                />
              </span>

              <p>Organization commission</p>
            </NavLink>
          </li>*/}

          <li className={SettingsPage && sidebarCss.active}>
            <NavLink to="/admin/setting" className={sidebarCss.wrapper_link}>
              <span>
                <img
                  alt=""
                  className={`${sidebarCss.wrapper_img} img-fluid`}
                  src={Setting}
                />
              </span>

              <p>Settings</p>
            </NavLink>
          </li>

          <li >
            <button
              className={`${sidebarCss.wrapper_link} bg-transparent border-0`}
              onClick={() => logout()}
            >
              <span>
                <img
                  alt=""
                  className={`${sidebarCss.wrapper_img} img-fluid`}
                  src={Logout}
                />
              </span>

              <p>Logout</p>
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
